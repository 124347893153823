export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const globalStore = useGlobalStore();

  if (process.env.NODE_ENV === 'production') {
    (function(d) {
      var o = function() { o.api.push(arguments) }, h = d.getElementsByTagName('head')[0];
      var c = d.createElement('script'); o.api = new Array(); c.async = true; c.type = 'text/javascript';
      c.charset = 'utf-8'; c.src = 'https://web-sdk.smartlook.com/recorder.js'; 
      c.onload = function() {
        // Initialize Smartlook only after the script has loaded
        window.smartlook('init', config.public.SMARTLOOK_API_KEY, { region: 'eu' });

        // Identify the user if authInfo is available
        if (globalStore.$state.authInfo?.member?.email) {
          window.smartlook('identify', globalStore.$state.authInfo?.member?.email, {
            account_email: globalStore.$state.authInfo?.account?.email,
            team_name: globalStore.$state.authInfo?.team?.name,
            member_role: globalStore.$state.authInfo?.memberRole
          });
        }

        // Add the Smartlook record configuration
        window.smartlook('record', { forms: true, numbers: true, emails: true, ips: true });
      };

      h.appendChild(c);
      window.smartlook = o;
    })(document);
  }
});
