import { Routes } from '~/constants'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.errorHandler = (err, vm, info) => {
        if (err && err.message.includes("/api/v1")){
            console.log("Excluding api error")
        } else if (err && err.statusCode === 404) {
            return navigateTo(Routes.NotFound)
        } else {
            return navigateTo(Routes.Error)
        }
    };
    
    nuxtApp.hook('vue:error', (err, context) => {
      console.error('SSR error:', err, context);
    });
});