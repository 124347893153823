
export default defineNuxtPlugin((nuxtApp) => {
    
  const store = useGlobalStore();
  const apiKey = store.$state?.currentTeam?.apiKey || '';

  const apiBaseURL = 'http://localhost:8080/api/v1';
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'HEADER_TEAM_API_KEY': apiKey,
  };

  async function makeRequest(method, endpoint, data = null) {
    try {
      const response = await $fetch(`${apiBaseURL}/${endpoint}`, {
        method,
        headers: {
          ...defaultHeaders,
        },
        body: data ? JSON.stringify(data) : undefined,
      });

      return await response;
    } catch (error) {
      console.error('Error in API request:', error);
      throw error;
    }
  }

  nuxtApp.provide('api', {
    get: (endpoint) => makeRequest('GET', endpoint),
    post: (endpoint, data) => makeRequest('POST', endpoint, data),
    put: (endpoint, data) => makeRequest('PUT', endpoint, data),
    delete: (endpoint) => makeRequest('DELETE', endpoint),
  });
});
