import { Routes } from '~/constants'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export const isAuthRoute = (route) => {
  const path = route?.fullPath;
  return route && (path.includes(Routes.Login) || path.includes(Routes.Register) || path.includes(Routes.ResetPassword));
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  console.log('going to',to)
  const store = useGlobalStore()
  const route = useRoute();
  const { $auth } = useNuxtApp()
  if ($auth) {
    const user = $auth.currentUser;
    const isAdmin = route.query.isAdmin === 'true';
    if (user) {
      store.globalSpinnerStart();
      const token = await user.getIdToken()
      const res = await store.fetchAuthInfo({ token })
      store.globalSpinnerStop();

      if (res === "auth/member-not-found") {
        await store.signOut()
        let inv = route.query.invitation || "";
        
        if (inv !== "") {
          toast('Invitation link expired. Please try again with valid link', {
            "theme": "auto",
            "type": "error",
            "hideProgressBar": false,
            "autoClose": 5000,
            "transition": "slide",
          })
          return await navigateTo(Routes.Login + "?invitation=" + inv)
        }
      }

      if(store.$state.authInfo?.account?.IsForbid === true) {
        await store.signOut()
        toast('Account forbidden. Please contact support.', {
          "theme": "auto",
          "type": "warning",
          "hideProgressBar": false,
          "autoClose": 5000,
          "transition": "slide",
        })
      }

      const onboardingInfoAdded = store.$state.authInfo?.account?.OnboardingInfoAdded
      console.log('[auth - middleware] user logged in onboarding info added: ', onboardingInfoAdded)

      if (!onboardingInfoAdded && !to.path.includes(Routes.Onboarding)) {
        return await navigateTo(Routes.Onboarding)
      }

      if (isAuthRoute(to) && !isAdmin) {
        return await navigateTo(Routes.Home)
      }
    } else {
      console.log('[auth - middleware] no user here')
      if (!isAuthRoute(to)) {
        return await navigateTo(Routes.Login)
      }
    }
  }
})
