import Ably from "ably"
import { apiServerUrl, HEADER_MEMBER_API_KEY, HEADER_TEAM_API_KEY } from "~/constants";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

// Initialize the Ably Realtime client
function createTokenRequest() {
  const globalStore = useGlobalStore();
  if (!globalStore.$state.authInfo) {
   throw createError({ statusCode: 404, statusMessage: 'Auth Info not setup' })
  }

  const apiKey = globalStore.$state?.currentTeam?.apiKey ?? "";
  return new Promise((resolve, reject) => {
    useFetch(apiServerUrl() + "team/ably/token-request", {
      method: "GET",
      headers: {
        [HEADER_TEAM_API_KEY]: apiKey,
      },
    }).then((res) => {
        const tokenRes = res.data.value;
        const error = res.error.value;
        if (error) {
          console.log("token request error: ", error);
          reject(error);
        } else {
          console.log("token request", tokenRes)
          resolve(tokenRes)
        }
      }
    ).catch((e) => {
      reject(e);
    });
  })
}

export default defineNuxtPlugin((nuxtApp) => {
  let ablyClient = null;

  function initChannel() {
    const globalStore = useGlobalStore(); 
    let accId = globalStore.$state.authInfo?.account?.Id;
    let channel = ablyClient.channels.get("message-" + accId);

    channel.subscribe(({ data }) => {
      console.log("Message received from Ably for event type => ", data.type);
      switch (data.type) {
        case 0: {  // Updated Auth Info 
          globalStore.ablyAuthInfoUpdate({data: data.authInfo})
          break;
        }
        case 1: {  // Updated Account
          globalStore.ablyAccountUpdate({data: data.account})
          break;
        }
        case 2: {  // Updated Team
          globalStore.ablyTeamUpdate({data: data.team})
          break;
        }
        case 3: {  // Updated Member
          globalStore.ablyMemberUpdate({data: data.member})
          break;
        }
        case 4: {  // Updated Member Role
          globalStore.ablyMemberRoleUpdate({data: data.memberRole})
          break;
        }
        case 5: { //File import succeded
          if(data?.data?.length !== 0){
            toast(data.data, {
              theme: "auto",
              type: "success",
              hideProgressBar: false,
              autoClose: 3000,
              transition: "slide",
          });
          }
        }
      }
    });
  }

  const initializeAbly = () => {
    const globalStore = useGlobalStore();
    try {
      ablyClient = new Ably.Realtime({
        authCallback: async (tokenParams, callback) => {
          if (globalStore.$state.authInfo !== null) {
            try {
              const tokenRequest = await createTokenRequest();
              console.log("Token params", tokenParams);
              callback(null, tokenRequest);
              await ablyClient.connection.once("connected");
              initChannel();
            } catch (error) {
              console.log("Error getting token request : ", error)
              callback(error, null);
            }
          }
        },
        disconnectedRetryTimeout: 2000,
      });
      nuxtApp.provide('ably', ablyClient)
    } catch (error) {
      console.error("Error initializing Ably Realtime client:", error);
    }
  };

  // Call initializeAbly here to start attempting to authenticate
  initializeAbly();
});
