export default defineNuxtRouteMiddleware(async (to, from) => {
//   const workflowStore = useWorkflowStore();
//   const goingTo = to.fullPath;
//   if (goingTo.includes("workflow") && goingTo.includes("table")) {
//     // alert('Your Data will be lost !');
//     console.log("data");
//     await navigateTo(to.fullPath);
//     workflowStore.resetState();
//   }
});
