<template>
  <div
    class="flex items-center justify-center min-h-screen bg-blue-900 bg-fixed bg-cover bg-bottom error-bg"
    style="
      background-image: url(
        data:image/svg + xml,
        %3Csvgxmlns='http://www.w3.org/2000/svg'viewBox='0 0 1600 900'%3E%3Cpolygonfill='%23f0b608'points='957 450 539 900 1396 900'/%3E%3Cpolygonfill='%23e6d710'points='957 450 872.9 900 1396 900'/%3E%3Cpolygonfill='%23e7af05'points='-60 900 398 662 816 900'/%3E%3Cpolygonfill='%23e7d808'points='337 900 398 662 816 900'/%3E%3Cpolygonfill='%23d8a408'points='1203 546 1552 900 876 900'/%3E%3Cpolygonfill='%23f1e213'points='1203 546 1552 900 1162 900'/%3E%3Cpolygonfill='%23f0b607'points='641 695 886 900 367 900'/%3E%3Cpolygonfill='%23e4d506'points='587 900 641 695 886 900'/%3E%3Cpolygonfill='%23eab822'points='1710 900 1401 632 1096 900'/%3E%3Cpolygonfill='%23e8da14'points='1710 900 1401 632 1365 900'/%3E%3Cpolygonfill='%23e8b008'points='1210 900 971 687 725 900'/%3E%3Cpolygonfill='%23edde14'points='943 900 1210 900 971 687'/%3E%3C/svg%3E
      );
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-sm-8 offset-sm-2 text-gray-50 text-center -mt-52">

        <!-- 404 Section UI -->
        <div v-if="error?.statusCode === 404">
          <div class="relative">
            <h1
              class="relative text-9xl tracking-tighter-less text-shadow font-sans font-bold">
              <span>4</span><span>0</span><span>4</span>
            </h1>
            <span class="absolute top-0 -ml-12 text-gray-300 font-semibold">Oops!</span>
          </div>
          <h5 class="text-gray-300 font-semibold -mr-10 -mt-3">
            Page not found
          </h5>
          <p class="text-gray-100 mt-2 mb-6">
            we are sorry, but the page you requested was not found
          </p>
        </div>

        <!-- Dang! internal something broke UI -->
        <div v-else id="section-some-error">
          <div class="relative">
            <h1
              class="relative text-9xl tracking-tighter-less text-shadow font-sans font-bold">
              <span>D</span><span>A</span><span>N</span><span>G!</span>
            </h1>
          </div>
          <h5 class="text-gray-300 font-semibold -mr-10 -mt-3">
            Something broke.
          </h5>
          <p class="text-gray-100 mt-2 mb-6">
            Our engineers are back to fixing it.
          </p>
        </div>

          <a href="/dashboard">
            <button
              class="bg-emerald-400 hover:bg-emerald-500 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500 rounded-md px-4 py-2 text-sm font-medium">
              Return to SendX Dashboard
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.error-bg {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23f0b608' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23e6d710' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23e7af05' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23e7d808' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23d8a408' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%23f1e213' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23f0b607' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23e4d506' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23eab822' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23e8da14' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23e8b008' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23edde14' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
}
.tracking-tighter-less {
  letter-spacing: -0.75rem;
}
.text-shadow {
  text-shadow: -8px 0 0 rgb(102 123 242);
}
</style>

<script setup>
const error = useError()

const handleError = async () => {
  await navigateTo('/dashboard')
}
</script>
