import { Routes,AppEntities } from '~/constants'


export const hasPermission = (route, entity, ...args) => {
    const globalStore = useGlobalStore();
    const path = route.fullPath;
    let flag = true;
    for(let i = 0; i<args.length;i++){
        flag = flag && path.split('/').includes(args[i]);
    }
    return flag && !globalStore.checkVisibilitity({entity: entity})
}

export default defineNuxtRouteMiddleware(async (to) => {
//   console.log(to.fullPath.includes('contact'), globalStore.checkVisibilitity({entity: 0}),to, "from permissions middleware");
  if(hasPermission(to, AppEntities.Contact, 'contact')){
    console.log('this is working tags');
    return await navigateTo(Routes.Home);
  }
  if(hasPermission(to, AppEntities.Contact, 'tag', 'details')){
    console.log('this is working tags');
    return await navigateTo(Routes.Home);
  }
  if(hasPermission(to, AppEntities.Contact, 'tag', 'import')){
    console.log('this is working tags');
    return await navigateTo(Routes.Home);
  }
  if(hasPermission(to, AppEntities.Contact, 'list', 'details')){
    console.log('this is working lists');
    return await navigateTo(Routes.Home);
  }
  if(hasPermission(to, AppEntities.Contact, 'list', 'import')){
    console.log('this is working lists');
    return await navigateTo(Routes.Home);
  }
  if(hasPermission(to, AppEntities.Campaign, 'campaign', 'new')){
    console.log('this is working lists');
    return await navigateTo(Routes.Home);
  }
  if(hasPermission(to, AppEntities.Campaign, 'campaign', 'edit')){
    console.log('this is working lists');
    return await navigateTo(Routes.Home);
  }
  if(hasPermission(to, AppEntities.Form, 'form', 'new')){
    console.log('this is working lists');
    return await navigateTo(Routes.Home);
  }
  if(hasPermission(to, AppEntities.Form, 'form', 'edit')){
    console.log('this is working lists');
    return await navigateTo(Routes.Home);
  }
  if(hasPermission(to, AppEntities.Automation, 'automations', 'edit')){
    console.log('this is working lists');
    return await navigateTo(Routes.Home);
  }
  if (hasPermission(to, AppEntities.Audience, "segment", "edit")) {
    console.log("this is working lists");
    return await navigateTo(Routes.Home);
  }
  if (hasPermission(to, AppEntities.TeamPreferences, "setting", "team-preference")) {
    console.log("this is working lists");
    return await navigateTo("/setting");
  }
  if (hasPermission(to, AppEntities.Member, "setting", "member")) {
    console.log("this is working lists");
    return await navigateTo("/setting");
  }
  if (hasPermission(to, AppEntities.BulkAction, "setting", "bulk-action")) {
    console.log("this is working lists");
    return await navigateTo("/setting");
  }
  if (hasPermission(to, AppEntities.Billing, "setting", "billing")) {
    console.log("this is working lists");
    return await navigateTo("/setting");
  }
  if (hasPermission(to, AppEntities.Domain, "setting", "domain-whitelisting")) {
    console.log("this is working lists");
    return await navigateTo("/setting");
  }
  if (hasPermission(to, AppEntities.CustomField, "setting", "custom-field")) {
    console.log("this is working lists");
    return await navigateTo("/setting");
  }
  if (hasPermission(to, AppEntities.RssFeed, "setting", "feed")) {
    console.log("this is working lists");
    return await navigateTo("/setting");
  }
  if (hasPermission(to, AppEntities.Contact, "popup", "overview", "submission")) {
    console.log("this is working lists");
    return await navigateTo("/form/table/popup");
  }

  if (hasPermission(to, AppEntities.Templates, "template", "edit")) {
    console.log("this is working lists");
    return await navigateTo("/setting/template");
  }

  if (hasPermission(to, AppEntities.Templates, "template", "new?type=landingpage")) {
    console.log("this is working lists");
    return await navigateTo("/setting/template");
  }
  if (hasPermission(to, AppEntities.Templates, "template", "new?type=email")) {
    console.log("this is working lists");
    return await navigateTo("/setting/template");
  }

  if (hasPermission(to, AppEntities.Contact, "embedded", "overview", "submission")) {
    console.log("this is working lists");
    return await navigateTo("/form/table/embed");
  }
  if (hasPermission(to, AppEntities.Contact, "landing-page", "overview", "submission")) {
    console.log("this is working lists");
    return await navigateTo("/form/table/landing-page");
  }
});
