import { initializeApp } from 'firebase/app'
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth"
import { useGlobalStore } from '../stores/global/store'

export default defineNuxtPlugin(async nuxtApp => {
    const store = useGlobalStore();
    const config = useRuntimeConfig()
    const firebaseConfig = {
        apiKey: config.public.apiKey,
        authDomain: config.public.authDomain,
        projectId: config.public.projectId,
        storageBucket: config.public.storageBucket,
        messagingSenderId: config.public.messagingSenderId,
        appId: config.public.appId,
    }
    const app = initializeApp(firebaseConfig)
    const auth = getAuth(app);
    await setPersistence(auth, browserLocalPersistence);
    store.initUser();
    nuxtApp.vueApp.provide('auth', auth)
    nuxtApp.provide('auth', auth)
})