import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const router = useRouter();

    if (!config.public.SENTRY_DSN_PUBLIC) {
        return
    }

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: config.public.SENTRY_DSN_PUBLIC || null,
        environment: config.public.env,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: Number(config.public.SENTRY_TRACES_SAMPLE_RATE),

        // // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https://demo.sendx.io'],

        // // This sets the sample rate. You may want this to be 100% while
        // // in development and sample at a lower rate in production
        replaysSessionSampleRate: Number(config.public.SENTRY_REPLAY_SAMPLE_RATE),

        // // If the entire session is not sampled, use the below sample rate to sample
        // // sessions when an error occurs.
        replaysOnErrorSampleRate: Number(config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE),
    });
});
