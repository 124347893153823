
export default defineNuxtRouteMiddleware(async (to, from) => {
  const audienceListStore = useAudienceListStore();
  const audienceTagStore = useAudienceTagStore();
  const goingTo = to.fullPath;
  if(!goingTo.includes('import')){
    // alert('Your Data will be lost !');
    await navigateTo(to.fullPath);
    audienceListStore.setResource({resource: 'isUploading', value: false})
    audienceListStore.setResource({resource: 'uploaded', value: false})
    audienceListStore.setResource({resource: 'error', value: false})
    audienceTagStore.setResource({ resource: "isUploading", value: false });
    audienceTagStore.setResource({ resource: "uploaded", value: false });
    audienceTagStore.setResource({ resource: "error", value: false });
  }
})
